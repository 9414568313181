<template>
  <section class="cure" id="cure">
    <div class="container cure__container">
      <div class="cure__description">
        <h3 class="content__secondary-title cure__title">
          Як лікувати молочницю?
        </h3>
        <p class="cure__text">
          Ваше здоров’я – наш пріоритет. Тому при перших проявах молочниці, ми
          радимо не відкладати лікування та одразу ж звернутися до лікаря.<sup>3</sup>
        </p>
        <p class="cure__text">
          Головною метою медикаментозної терапії є ліквідація збудника в
          осередку запалення. У більшості випадків з цією задачею можуть
          впоратися місцеві протигрибкові препарати.<sup>3,4</sup>
        </p>
        <p class="cure__text">
          <strong>
            Проте останнім часом присутня тенденція до збільшення
            різноманітності збудників молочниці, які погано піддають
            <nobr>лікуванню.<sup>4</sup></nobr> Зменшенню чутливості до терапії
            (наприклад, до флуконазолу чи кетоконазолу) також сприяє
            неконтрольований прийом препаратів, тривале їх використання або
            неправильне дозування.<sup>1,5</sup>
          </strong>
        </p>
      </div>
      <div class="cure__image">
        <img src="@/assets/img/cure-1.png" alt="" />
        <div
          class="cure__elips parallax__layer"
          :class="{ active: isVisCure }"
          data-speed="4"
        ></div>
        <svg
          :class="{ active: isVisCure }"
          class="parallax__layer"
          data-speed="10"
          width="144"
          height="142"
          viewBox="0 0 144 142"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M29.5895 71.865L31.081 71L29.5895 70.135L1.36378 53.7648L21.8061 18.2378L50.0432 34.5767L51.5432 35.4446L51.544 33.7116L51.5599 1H92.4401L92.456 33.7116L92.4568 35.4446L93.9568 34.5767L122.194 18.2378L142.636 53.7648L114.41 70.135L112.919 71L114.41 71.865L142.636 88.2352L122.194 123.762L93.9568 107.423L92.4568 106.555L92.456 108.288L92.4401 141H51.5599L51.544 108.288L51.5432 106.555L50.0432 107.423L21.8061 123.762L1.36378 88.2352L29.5895 71.865Z"
            stroke="#19468B"
            stroke-width="2"
          />
        </svg>
      </div>
    </div>

    <div class="cure__lomexin lomexin" id="lomexin">
      <div class="lomexin__container container">
        <div class="lomexin__title" :class="{ active: isVisLomexin }">
          <div class="lomexin__row">
            <h1 class="content__primary-title lomexin__animated">Впоратися</h1>
          </div>
          <div class="lomexin__row">
            <h1 class="content__primary-title lomexin__animated">
              <nobr>з молочницею</nobr>
            </h1>
          </div>
          <div class="lomexin__row">
            <h1 class="content__primary-title lomexin__animated">допоможе</h1>
          </div>
          <div class="lomexin__row">
            <h1 class="content__primary-title lomexin__animated">
              <strong>Ломексин<sup>®</sup>!</strong>
            </h1>
          </div>
        </div>

        <div class="lomexin__text" :class="{ active: isVisLomexin }">
          <p>
            Ломексин<sup>®</sup> – це оригінальний препарат&nbsp;
            &nbsp;<nobr><span class="marked">фентиконазолу</span>&nbsp;&nbsp;&nbsp;для</nobr> місцевого
            застосування, який сприяє усуненню свербежу, печіння та виділення з
            піхви всього за 1 день використання м’якої вагінальної капсули у
            дозі 600 мг.**
          </p>
          <p>
            Фентиконазол має широкий спектр дії та проявляє високу активність до
            дріжджових грибів роду <nobr>Candida.<sup>2</sup></nobr>
          </p>
        </div>
      </div>

      <svg
        width="258"
        height="134"
        viewBox="0 0 258 134"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M-8 131.579V2.42061L56.3384 67L-8 131.579ZM58.75 67.4131L59.1616 67L58.75 66.5869V2.42061L123.088 67L58.75 131.579V67.4131ZM125.5 131.579V67.4131L125.912 67L125.5 66.5869V2.4206L189.838 67L125.5 131.579ZM192.25 67.4131L192.662 67L192.25 66.5869V2.4206L256.588 67L192.25 131.579V67.4131Z"
          stroke="white"
          stroke-width="2"
        />
      </svg>

      <div class="lomexin__slider">
        <div class="lomexin__list">
          <li class="lomexin__item">
            <img src="@/assets/img/lomexin-1.png" alt="" />
          </li>
          <li class="lomexin__item">
            <img src="@/assets/img/lomexin-2.png" alt="" />
          </li>
          <li class="lomexin__item">
            <img src="@/assets/img/lomexin-3.png" alt="" />
          </li>
          <li class="lomexin__item">
            <img src="@/assets/img/lomexin-4.png" alt="" />
          </li>
        </div>
      </div>

      <div class="lomexin__buttons">
        <button
          class="lomexin__btn btn lomexin__btn--drugstore gtm__find-in-drugstore"
          @click="openPopup('LEAVE')"
          onClick="gtag('event', 'click', { 'event_category': 'кнока `Знайти в аптеці` лікування', 'event_label': 'кнока `Знайти в аптеці` лікування'})"
        >
          Знайти в аптеці
        </button>
        <button
          class="lomexin__btn btn lomexin__btn--instruction gtm__instructions"
          @click="openPopup('INSTRUCTIONS')"
        >
          Інструкція
        </button>
      </div>

      <div class="lomexin__run">
        <p class="lomexin__run-text content__primary-title">
          <MarqueeText :duration="30">
            <nobr><strong>Ломексин<sup>®</sup></strong> – крапка в історії молочниці.<strong>Ломексин<sup>®</sup></strong> – крапка в історії молочниці.<strong>Ломексин<sup>®</sup></strong> – крапка в історії молочниці.</nobr>
          </MarqueeText>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import { getElementVisible } from "@/helper.js";
import MarqueeText from 'vue-marquee-text-component';

export default {
  components: {
    MarqueeText,
  },
  data: () => ({
    speed: null,
    yPos: null,

    isVisLomexin: false,
    isVisCure: false,
  }),
  methods: {
    parallax() {
      const layers = document.querySelectorAll(".cure .parallax__layer");
      const target = document.getElementById("cure");
      const top = target.getBoundingClientRect().top;

      [...layers].forEach((item) => {
        this.speed = item.dataset.speed;
        this.yPos = (window.innerWidth - top * this.speed) / 100;
        item.style.transform = `translate(0px, ${this.yPos}px) rotate(-17.77deg)`;
      });
    },
    animate() {
      if (getElementVisible("cure")) {
        this.isVisCure = true;
      }
      if (getElementVisible("lomexin")) {
        this.isVisLomexin = true;
      }
    },
    openPopup(popup) {
      this.$emit("openPopup", popup);
    },
  },
  created() {
    window.addEventListener("scroll", this.parallax);
    window.addEventListener("scroll", this.animate);
  },
  destroyed() {
    window.removeEventListener("scroll", this.parallax);
    window.removeEventListener("scroll", this.animate);
  },
};
</script>

<style lang="scss" scoped>
.cure {
  position: relative;
  width: 100%;
  padding: 153px 0 0;
  background-color: #bcf1ef;
  border-radius: 60px 60px 0 0;
  overflow: hidden;

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 160px;
  }

  &__description {
    width: 45%;
  }

  &__text {
    margin-bottom: 40px;
    font-size: 22px;
    line-height: 150%;
    color: #19468b;
  }

  &__image {
    position: relative;

    img {
      position: relative;
      z-index: 1;
    }

    svg {
      position: absolute;
      z-index: 2;
      width: 144px;
      height: 144px;
      top: -30px;
      right: -30px;
      opacity: 0;
      transition: opacity 0.8s;
      transition-delay: 1s;

      &.active {
        opacity: 1;
      }
    }
  }

  &__elips {
    position: absolute;
    left: 0;
    bottom: -60px;
    width: 415px;
    height: 215px;
    border-radius: 50%;
    border: 2px solid #19468b;
    opacity: 0;
    transition: opacity 0.8s;
    transition-delay: 1s;

    &.active {
      opacity: 1;
    }
  }

  .lomexin {
    padding: 157px 0 80px;
    background: #06a19b;
    border-radius: 60px 60px 0 0;
    color: #fff;

    svg {
      position: absolute;
      bottom: 940px;
      width: 260px;
    }

    &__container {
      display: flex;
      justify-content: space-between;
    }

    &__animated {
      transform: translateY(120%);
      transition: 0.8s;
      transition-delay: 0.4s;
    }

    &__title {
      &.active {
        .lomexin__animated {
          transform: translateY(0);
        }
      }
    }

    &__row {
      overflow: hidden;
      margin-bottom: 10px;

      &:last-of-type {
        margin: 0;
      }
    }

    &__text {
      width: 32%;
      font-size: 18px;
      line-height: 150%;
      text-transform: uppercase;
      color: #ffffff;
      opacity: 0;
      transition: 0.8s;
      transition-delay: 0.6s;

      p {
        margin-bottom: 15px;
      }

      &.active {
        opacity: 1;
      }
    }

    &__slider {
      overflow: hidden;
      height: 400px;
    }

    &__list {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 100%;
      list-style: none;
      width: 400px;
      height: 100vw;
      overflow-y: auto;
      overflow-x: hidden;
      transform: rotate(-90deg) translateY(-400px);
      transform-origin: right top;
      -ms-overflow-style: none; /* IE 11 */
      scrollbar-width: none; /* Firefox 64 */

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__item {
      min-width: 50%;
      transform: rotate(90deg);

      img {
        min-width: 100%;
      }

      &:nth-of-type(2) {
        transform: translateY(-24%) rotate(90deg);
      }
      &:nth-of-type(3) {
        min-width: 180%;
        transform: translateY(-15%) rotate(90deg);
      }
      &:nth-of-type(4) {
        min-width: 180%;
        transform: translateY(31%) rotate(90deg);
      }
    }

    &__buttons {
      position: relative;
      display: flex;
      justify-content: center;
      min-height: 200px;
    }

    &__btn {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 236px;
      height: 123px;
      font-weight: bold;
      font-size: 20px;
      line-height: 19px;
      cursor: pointer;
      transition: all 0.4s;

      &::before {
        content: "";
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        border-radius: 50%;
        transition: all 0.4s;
      }

      &:hover {
        &::before {
          transform: rotate(0deg);
        }
      }

      &--drugstore {
        color: #06a19b;
        transform: translate(-70px, -30px);
        z-index: 2;

        &::before {
          background: #bcf1ef;
          transform: rotate(-18deg);
        }

        &:active {
          &::before {
            background-color: #dcfefd;
          }
        }
      }

      &--instruction {
        color: #fff;
        transform: translate(70px, 30px);

        &::before {
          border: 1px solid #fff;
          transform: rotate(-10deg);
        }

        &:active {
          color: #06a19b;

          &::before {
            background-color: #fff;
          }
        }
      }
    }
    &__run {
      max-width: 100%;
      overflow: hidden;
      display: flex;

      strong {
        margin-left: 20px;
      }
    }
  }
}

@media (max-width: 1441px) {
  .cure .lomexin svg {
    width: 200px;
    left: -5%;
    bottom: 920px;
  }
}

@media (max-width: 1100px) {
  .cure {
    padding: 115px 0 0;

    &__container {
      padding-bottom: 120px;
    }

    &__description {
      width: 50%;
    }

    &__text {
      margin-bottom: 40px;
      font-size: 18px;
    }

    &__image {
      position: relative;
      width: 45%;

      img {
        max-width: 100%;
      }

      svg {
        width: 40%;
        top: -60px;
        right: 0;
      }
    }

    &__elips {
      left: 0;
      bottom: -60px;
      width: 100%;
      height: 65%;
    }

    .lomexin {
      padding: 100px 0 120px;

      svg {
        bottom: 732px;
        left: 0;
      }

      &__row {
        margin-bottom: 8px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &__text {
        width: 50%;
        font-size: 12px;
        padding-top: 140px;
      }

      &__buttons {
        position: relative;
        display: flex;
        justify-content: center;
        min-height: 200px;
      }

      &__btn {
        width: 200px;
        height: 104px;
        font-size: 16px;
        line-height: 15px;

        &--instruction {
          color: #fff;
          transform: translate(60px, 10px);

          &::before {
            border: 1px solid #fff;
            transform: rotate(-10deg);
          }

          &:active {
            color: #06a19b;

            &::before {
              background-color: #fff;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 675px) {
  .cure {
    padding: 75px 0 0;
    border-radius: 40px 40px 0 0;

    &__container {
      flex-direction: column;
      padding-bottom: 140px;
    }

    &__description {
      width: 100%;
    }

    &__title {
      width: 90%;
    }

    &__text {
      font-size: 16px;
    }

    &__image {
      margin-top: 50px;
      width: 100%;

      svg {
        width: 35%;
        top: -90px;
      }
    }

    .lomexin {
      padding: 75px 0 66px;
      border-radius: 40px 40px 0 0;

      svg {
        bottom: 30%;
        left: -8%;
        width: 97px;
      }

      &__container {
        flex-direction: column;
        padding-bottom: 0;
      }

      &__row {
        margin-bottom: 5px;
      }

      &__text {
        width: 75%;
        font-size: 10px;
        padding-top: 51px;
        margin-left: auto;
      }

      &__slider {
        height: 400px;
      }

      &__list {
        justify-content: stretch;
        flex-direction: row;
        padding: 0;
        height: 400px;
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        transform: translateY(-20px);
     }

      &__item {
        min-width: 65%;
        transform: rotate(0);

        &:nth-of-type(2) {
          transform: translateX(-30%) rotate(0);
        }
        &:nth-of-type(3) {
          min-width: 105%;
          transform: translateX(-40%) rotate(0);
        }
        &:nth-of-type(4) {
          min-width: 105%;
          transform: translateX(-60%) rotate(0);
        }
      }

      &__buttons {
        position: relative;
        display: flex;
        justify-content: center;
        min-height: 150px;
      }

      &__btn {
        width: 135px;
        height: 70px;
        font-size: 12px;
        line-height: 11px;

        &--drugstore {
          transform: translate(-30px, -20px);
        }

        &--instruction {
          color: #fff;
          transform: translate(30px, 20px);

          &::before {
            border: 1px solid #fff;
            transform: rotate(-10deg);
          }

          &:active {
            color: #06a19b;

            &::before {
              background-color: #fff;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 420px) {
  .cure {
    .lomexin {
      &__item {
        min-width: 115%;
        transform: rotate(0);

        &:nth-of-type(1) {
          transform: rotate(0) translateX(-16.5%);
        }
        &:nth-of-type(2) {
          transform: rotate(0) translateX(-55%);
        }
        &:nth-of-type(3) {
          min-width: 175%;
          transform: translateX(-60%) rotate(0);
        }
        &:nth-of-type(4) {
          min-width: 175%;
          transform: translateX(-82.5%) rotate(0);
        }
      }

      &__run {
        strong {
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
