<template>
  <section class="about" id="about">
    <div class="container about__container">
      <div class="about__description">
        <h2 class="content__secondary-title about__title">
          Про молочницю та її причини
        </h2>
        <p class="content__text about__text">
          Двоє з трьох жінок хоча б раз у житті мають справу з молочницею. Це
          запальне захворювання слизової оболонки піхви, збудником якого є&nbsp;
          <nobr class="marked">дріжджові гриби</nobr>&nbsp; роду&nbsp;
          <span class="marked">Candida</span>&nbsp; . Ці мікроорганізми є
          частиною нормальної вагінальної мікрофлори. Проте низка факторів, як
          от зниження імунітету чи прийом гормональних контрацептивів, можуть
          сприяти надмірному їх <nobr>розмноженню.<sup>3,4</sup></nobr>
        </p>
      </div>

      <h3 class="content__third-title">
        Фактори ризику молочниці<sup>4</sup>:
      </h3>
    </div>
    <ul class="about__factors" :class="{ active: isVisFactors }" id="factors">
      <li class="about__factor">
        <div class="container">
          <p class="about__factor-title">Ендокринні</p>
          <ul class="about__factor-list">
            <li class="about__factor-item">цукровий діабет</li>
            <li class="about__factor-item">гіпотиреоз</li>
          </ul>
        </div>
      </li>
      <li class="about__factor">
        <div class="container">
          <p class="about__factor-title">Механічні</p>
          <ul class="about__factor-list">
            <li class="about__factor-item">синтетична білизна</li>
            <li class="about__factor-item">травматичне пошкодження піхви</li>
          </ul>
        </div>
      </li>
      <li class="about__factor">
        <div class="container">
          <p class="about__factor-title">Фізіологічні</p>
          <ul class="about__factor-list">
            <li class="about__factor-item">вагітність</li>
            <li class="about__factor-item">місячні</li>
          </ul>
        </div>
      </li>
      <li class="about__factor">
        <div class="container">
          <p class="about__factor-title">Інші</p>
          <ul class="about__factor-list">
            <li class="about__factor-item">антибіотики</li>
            <li class="about__factor-item">кортикостероїди</li>
            <li class="about__factor-item">
              <nobr>комбіновані оральні контрацептиви</nobr>
            </li>
            <li class="about__factor-item">стрес</li>
          </ul>
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
import { getElementVisible } from "@/helper.js";

export default {
  data: () => ({
    isVisFactors: false,
  }),
  methods: {
    animate() {
      if (getElementVisible("factors")) {
        this.isVisFactors = true;
      }
    },
  },
  created() {
    window.addEventListener("scroll", this.animate);
  },
  destroyed() {
    window.removeEventListener("scroll", this.animate);
  },
};
</script>

<style lang="scss" scoped>
.about {
  overflow: hidden;

  &__description {
    display: flex;
    justify-content: space-between;
    padding: 150px 0;
  }

  &__title {
    width: 35%;
  }

  &__text {
    width: 50%;
    margin-left: 5%;
    font-size: 24px;
    line-height: 150%;
    color: #19468b;
  }

  &__factors {
    width: 100%;
    transform: translateY(60px);
    overflow: hidden;
  }

  &__factor {
    min-width: 100%;
    padding: 70px 0 130px;
    border-radius: 60px 60px 0 0;
    color: #fff;
    opacity: 0;
    transform: translateY(100%);
    transition-delay: 0.4s;
    transition: 0.8s;

    &:nth-of-type(1) {
      background-color: #55cbfa;
    }

    &:nth-of-type(2) {
      background-color: #38bdf1;
      transition-delay: 0.2s;
    }

    &:nth-of-type(3) {
      background-color: #3db0de;
      transition-delay: 0.4s;
    }

    &:nth-of-type(4) {
      background-color: #2b9ac5;
      padding-bottom: 70px;
      transition-delay: 0.6s;
    }

    &-title {
      display: block;
      font-size: 36px;
      line-height: 120%;
      text-transform: uppercase;
      margin-bottom: 40px;
    }

    &-list {
      display: flex;
      align-items: baseline;
      flex-wrap: wrap;
    }

    &-item {
      padding: 10px;
      margin-right: 15px;
      margin-bottom: 5px;
      font-size: 24px;
      text-align: center;
      border-radius: 60px;
      border: 2px solid;

      &:last-of-type {
        margin: 0;
      }
    }
  }

  .about__factors.active {
    .about__factor {
      opacity: 1;

      &:nth-of-type(1) {
        transform: translateY(0);
      }

      &:nth-of-type(2) {
        transform: translateY(-60px);
      }

      &:nth-of-type(3) {
        transform: translateY(-120px);
      }

      &:nth-of-type(4) {
        transform: translateY(-180px);
      }
    }
  }
}

@media (max-width: 1100px) {
  .about {
    &__description {
      flex-direction: column;
      padding: 109px 0;
    }

    &__title {
      width: 50%;
    }

    &__text {
      width: 70%;
      margin: 67px 0 0 auto;
      font-size: 18px;
    }

    &__factors {
      transform: translateY(30px);
    }

    &__factor {
      padding: 54px 0 110px;

      &:nth-of-type(4) {
        padding-bottom: 50px;
      }

      &-title {
        font-size: 24px;
        margin-bottom: 30px;
      }

      &-item {
        padding: 8px;
        margin-right: 10px;
        font-size: 18px;
      }
    }
  }
}

@media (max-width: 675px) {
  .about {
    &__description {
      padding: 75px 0;
    }

    &__title {
      width: 60%;
    }

    &__text {
      width: 100%;
      margin: 0;
      font-size: 16px;
    }

    &__factors {
      transform: translateY(20px);
    }

    &__factor {
      padding: 37px 0 80px;
      border-radius: 40px 40px 0 0;

      &:nth-of-type(4) {
        padding-bottom: 40px;
      }

      &-title {
        font-size: 18px;
        margin-bottom: 20px;
      }

      &-item {
        padding: 8px 16px;
        margin-bottom: 8px;
        font-size: 14px;
        flex-shrink: 0;
      }
    }

    .about__factors.active {
      .about__factor {
        opacity: 1;

        &:nth-of-type(2) {
          transform: translateY(-40px);
        }

        &:nth-of-type(3) {
          transform: translateY(-80px);
        }

        &:nth-of-type(4) {
          transform: translateY(-120px);
        }
      }
    }
  }
}
</style>
