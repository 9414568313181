<template>
  <div class="symptoms">
    <div class="container symptoms__container">
      <p class="symptoms__text-upper">
        У піхві розвивається запальний процес, що завдає значного дискомфорту у
        повсякденному житті.
      </p>
      <h3 class="content__third-title">
        При молочниці присутні скарги <nobr>на<sup>4</sup>:</nobr>
      </h3>
      <ul
        class="symptoms__complains"
        :class="{ active: isVisComplains }"
        id="complains"
      >
        <li class="symptoms__complain">
          <svg
            width="80"
            height="70"
            viewBox="0 0 80 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 33.5C20 34.0506 19.6825 34.6514 18.9621 35.1556C18.2448 35.6577 17.2007 36 16 36C14.7993 36 13.7552 35.6577 13.0379 35.1556C12.3175 34.6514 12 34.0506 12 33.5C12 32.9494 12.3175 32.3486 13.0379 31.8444C13.7552 31.3423 14.7993 31 16 31C17.2007 31 18.2448 31.3423 18.9621 31.8444C19.6825 32.3486 20 32.9494 20 33.5Z"
              stroke="#19468B"
              stroke-width="2"
            />
            <path
              d="M22.388 28.7089C22.688 29.2369 22.7139 29.9422 22.3757 30.7191C22.0372 31.4964 21.3528 32.2811 20.372 32.8384C19.3912 33.3956 18.3667 33.5818 17.5258 33.4746C16.6852 33.3674 16.0926 32.9841 15.7926 32.4561C15.4926 31.9281 15.4667 31.2228 15.805 30.4458C16.1434 29.6686 16.8278 28.8838 17.8086 28.3266C18.7894 27.7693 19.8139 27.5831 20.6548 27.6904C21.4954 27.7975 22.088 28.1809 22.388 28.7089Z"
              stroke="#19468B"
              stroke-width="2"
            />
            <path
              d="M16.5547 25.208C17.0797 25.5133 17.4571 26.1097 17.5559 26.9512C17.6548 27.7932 17.4583 28.8158 16.8914 29.791C16.3244 30.7662 15.5329 31.4428 14.7523 31.7734C13.972 32.1039 13.267 32.071 12.742 31.7657C12.217 31.4605 11.8396 30.8641 11.7408 30.0225C11.6419 29.1805 11.8383 28.158 12.4053 27.1828C12.9723 26.2076 13.7638 25.531 14.5444 25.2003C15.3247 24.8698 16.0297 24.9028 16.5547 25.208Z"
              stroke="#19468B"
              stroke-width="2"
            />
            <path
              d="M20.9219 21.6712C21.4469 21.9764 21.8243 22.5728 21.9231 23.4144C22.022 24.2563 21.8255 25.2789 21.2585 26.2541C20.6916 27.2293 19.9001 27.9059 19.1194 28.2366C18.3392 28.5671 17.6342 28.5341 17.1092 28.2289C16.5842 27.9236 16.2068 27.3272 16.108 26.4856C16.0091 25.6437 16.2055 24.6211 16.7725 23.6459C17.3395 22.6707 18.131 21.9941 18.9116 21.6635C19.6919 21.333 20.3969 21.3659 20.9219 21.6712Z"
              stroke="#19468B"
              stroke-width="2"
            />
            <path
              d="M28.1104 25.6651C28.6354 25.9703 29.0128 26.5667 29.1116 27.4083C29.2104 28.2502 29.014 29.2728 28.447 30.248C27.88 31.2232 27.0885 31.8998 26.3079 32.2305C25.5277 32.5609 24.8226 32.528 24.2976 32.2228C23.7726 31.9175 23.3952 31.3211 23.2964 30.4795C23.1976 29.6376 23.394 28.615 23.961 27.6398C24.528 26.6646 25.3195 25.988 26.1001 25.6574C26.8804 25.3269 27.5854 25.3598 28.1104 25.6651Z"
              stroke="#19468B"
              stroke-width="2"
            />
            <path
              d="M28.3154 28.7878C28.8485 28.4971 29.5542 28.4835 30.3251 28.8352C31.0963 29.1872 31.869 29.8852 32.409 30.8756C32.949 31.866 33.1173 32.8936 32.9954 33.7325C32.8735 34.5711 32.4799 35.1569 31.9467 35.4476C31.4135 35.7383 30.7079 35.7519 29.937 35.4002C29.1658 35.0482 28.3931 34.3502 27.8531 33.3598C27.3131 32.3694 27.1448 31.3418 27.2667 30.5029C27.3885 29.6643 27.7822 29.0785 28.3154 28.7878Z"
              stroke="#19468B"
              stroke-width="2"
            />
            <path
              d="M21.9267 31.9831C22.4599 31.6924 23.1655 31.6788 23.9364 32.0305C24.7077 32.3825 25.4803 33.0805 26.0203 34.0709C26.5603 35.0613 26.7286 36.0889 26.6067 36.9278C26.4849 37.7664 26.0912 38.3522 25.5581 38.6429C25.0249 38.9337 24.3192 38.9472 23.5483 38.5955C22.7771 38.2435 22.0044 37.5455 21.4644 36.5551C20.9244 35.5647 20.7561 34.5371 20.878 33.6982C20.9999 32.8596 21.3935 32.2738 21.9267 31.9831Z"
              stroke="#19468B"
              stroke-width="2"
            />
            <path
              d="M32.4067 49.7766C31.8184 49.6258 31.293 49.1546 30.9689 48.3716C30.6447 47.5883 30.5555 46.5509 30.8357 45.4582C31.1159 44.3655 31.6935 43.4991 32.3547 42.9685C33.0156 42.4382 33.7029 42.278 34.2912 42.4289C34.8794 42.5798 35.4049 43.051 35.7289 43.8339C36.0532 44.6172 36.1424 45.6547 35.8622 46.7474C35.5819 47.84 35.0044 48.7065 34.3432 49.237C33.6823 49.7674 32.9949 49.9275 32.4067 49.7766Z"
              stroke="#19468B"
              stroke-width="2"
            />
            <path
              d="M44.7743 44.5068C44.255 44.8216 43.5507 44.8675 42.7645 44.5513C41.978 44.235 41.1742 43.5731 40.5894 42.6084C40.0046 41.6438 39.7895 40.625 39.8729 39.7813C39.9563 38.9381 40.3227 38.3349 40.842 38.0201C41.3613 37.7053 42.0656 37.6594 42.8518 37.9755C43.6383 38.2918 44.4421 38.9538 45.0269 39.9184C45.6116 40.883 45.8268 41.9018 45.7434 42.7455C45.66 43.5887 45.2936 44.192 44.7743 44.5068Z"
              stroke="#19468B"
              stroke-width="2"
            />
            <path
              d="M48.1084 38.5772C47.8181 39.1106 47.2327 39.5047 46.3942 39.6273C45.5554 39.7499 44.5277 39.5825 43.5368 39.0434C42.546 38.5042 41.8472 37.7322 41.4946 36.9612C41.1422 36.1906 41.1552 35.485 41.4454 34.9515C41.7357 34.4181 42.3212 34.024 43.1596 33.9014C43.9985 33.7787 45.0262 33.9462 46.017 34.4853C47.0079 35.0245 47.7066 35.7965 48.0592 36.5675C48.4117 37.3381 48.3987 38.0437 48.1084 38.5772Z"
              stroke="#19468B"
              stroke-width="2"
            />
            <path
              d="M56.3086 29.4566C56.0183 29.99 55.4329 30.3841 54.5944 30.5067C53.7556 30.6293 52.7278 30.4619 51.737 29.9228C50.7462 29.3836 50.0474 28.6115 49.6948 27.8406C49.3424 27.07 49.3554 26.3643 49.6456 25.8309C49.9359 25.2975 50.5214 24.9033 51.3598 24.7808C52.1987 24.6581 53.2264 24.8256 54.2172 25.3647C55.2081 25.9039 55.9068 26.6759 56.2594 27.4469C56.6119 28.2175 56.5989 28.9231 56.3086 29.4566Z"
              stroke="#19468B"
              stroke-width="2"
            />
            <path
              d="M57.9954 35.2887C57.4108 35.4529 56.7199 35.3084 56.0472 34.7932C55.3741 34.2778 54.777 33.4247 54.472 32.3387C54.167 31.2527 54.2327 30.2135 54.539 29.423C54.8452 28.6329 55.3598 28.1499 55.9445 27.9857C56.5291 27.8215 57.22 27.966 57.8927 28.4811C58.5658 28.9965 59.1629 29.8496 59.4679 30.9357C59.7729 32.0217 59.7073 33.0609 59.4009 33.8514C59.0947 34.6415 58.5801 35.1245 57.9954 35.2887Z"
              stroke="#19468B"
              stroke-width="2"
            />
            <path
              d="M64.9467 36.0953C64.6167 36.605 64.0029 36.9534 63.1575 37.0118C62.3118 37.0702 61.2998 36.825 60.3529 36.2119C59.406 35.5989 58.7681 34.7759 58.4752 33.9803C58.1825 33.1851 58.2492 32.4825 58.5792 31.9727C58.9093 31.4629 59.5231 31.1145 60.3684 31.0562C61.2142 30.9978 62.2262 31.243 63.1731 31.856C64.12 32.4691 64.7579 33.2921 65.0507 34.0877C65.3435 34.8829 65.2768 35.5855 64.9467 36.0953Z"
              stroke="#19468B"
              stroke-width="2"
            />
            <path
              d="M58.4222 29.0365C57.8375 29.2007 57.1467 29.0562 56.4739 28.541C55.8008 28.0256 55.2038 27.1725 54.8988 26.0865C54.5938 25.0005 54.6594 23.9613 54.9657 23.1708C55.2719 22.3807 55.7865 21.8977 56.3712 21.7335C56.9559 21.5693 57.6467 21.7138 58.3195 22.2289C58.9926 22.7443 59.5896 23.5974 59.8946 24.6835C60.1996 25.7695 60.134 26.8087 59.8277 27.5992C59.5215 28.3893 59.0069 28.8723 58.4222 29.0365Z"
              stroke="#19468B"
              stroke-width="2"
            />
            <path
              d="M56.3086 46.2307C56.0183 46.7642 55.4329 47.1583 54.5944 47.2809C53.7556 47.4035 52.7278 47.2361 51.737 46.6969C50.7462 46.1578 50.0474 45.3857 49.6948 44.6148C49.3424 43.8442 49.3554 43.1385 49.6456 42.6051C49.9359 42.0717 50.5214 41.6775 51.3598 41.5549C52.1987 41.4323 53.2264 41.5997 54.2172 42.1389C55.2081 42.6781 55.9068 43.4501 56.2594 44.221C56.6119 44.9916 56.5989 45.6973 56.3086 46.2307Z"
              stroke="#19468B"
              stroke-width="2"
            />
            <path
              d="M47.9785 50.1411C47.6883 50.6746 47.1028 51.0687 46.2643 51.1913C45.4255 51.3139 44.3978 51.1465 43.4069 50.6073C42.4161 50.0682 41.7174 49.2961 41.3648 48.5252C41.0123 47.7546 41.0253 47.0489 41.3156 46.5155C41.6058 45.9821 42.1913 45.5879 43.0297 45.4653C43.8686 45.3427 44.8963 45.5101 45.8872 46.0493C46.878 46.5885 47.5767 47.3605 47.9293 48.1314C48.2818 48.902 48.2688 49.6077 47.9785 50.1411Z"
              stroke="#19468B"
              stroke-width="2"
            />
            <path
              d="M44.8627 50.4353C45.1684 50.9601 45.2019 51.6651 44.8721 52.4456C44.5421 53.2265 43.8662 54.0186 42.8915 54.5864C41.9168 55.1542 40.8944 55.3515 40.0523 55.2534C39.2107 55.1553 38.6139 54.7784 38.3083 54.2537C38.0026 53.729 37.969 53.024 38.2988 52.2434C38.6288 51.4625 39.3047 50.6705 40.2794 50.1026C41.2541 49.5348 42.2765 49.3375 43.1186 49.4356C43.9603 49.5337 44.557 49.9106 44.8627 50.4353Z"
              stroke="#19468B"
              stroke-width="2"
            />
            <path
              d="M41.4877 44.138C41.7934 44.6627 41.8269 45.3677 41.4971 46.1482C41.1671 46.9291 40.4912 47.7212 39.5165 48.2891C38.5418 48.8569 37.5194 49.0542 36.6773 48.9561C35.8357 48.858 35.2389 48.4811 34.9333 47.9563C34.6276 47.4316 34.594 46.7266 34.9238 45.9461C35.2538 45.1652 35.9297 44.3731 36.9044 43.8053C37.8791 43.2374 38.9015 43.0401 39.7436 43.1383C40.5853 43.2363 41.182 43.6132 41.4877 44.138Z"
              stroke="#19468B"
              stroke-width="2"
            />
            <path
              d="M50.1508 57.9717C56.0057 52.0258 58.654 51.0518 64.6109 49.7612C73.2874 47.8815 73.6689 41.609 73.6689 38.4387C73.6689 34.3388 70.0836 27.7782 69.5928 22.1342C68.687 11.7174 62.3464 10.5639 58.2703 11.7174C52.1278 13.4558 47.8424 18.1021 40.6071 18.9639C33.3718 19.8256 26.5672 9.00002 19.7736 9C11.1685 8.99998 4.37489 15.7935 3.75973 26.0879C2.72046 43.4795 10.7812 45.2322 18.8677 45.2322C26.9542 45.2322 22.3383 52.8533 27.02 60.178C31.7016 67.5027 45.0116 63.1908 50.1508 57.9717Z"
              stroke="#A81D7B"
              stroke-width="2"
            />
          </svg>
          Густі білі виділення, які нагадають кисломолочний сир
        </li>
        <li class="symptoms__complain">
          <svg
            width="80"
            height="70"
            viewBox="0 0 80 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24.0757 24.6421C24.1106 24.8522 24.146 25.07 24.1826 25.2955C24.2444 25.6763 24.3097 26.0788 24.3825 26.5019C24.6008 27.771 24.8784 29.1687 25.3147 30.481C26.176 33.0721 27.7829 35.6642 31.1132 35.9703C31.5021 36.0061 31.8172 35.8292 32.0077 35.6158C32.1636 35.4413 32.2304 35.2543 32.2597 35.159C32.3213 34.9589 32.34 34.7404 32.3489 34.5777C32.3683 34.2266 32.3578 33.7353 32.3435 33.1803C32.3415 33.101 32.3394 33.0196 32.3372 32.936C32.3077 31.8159 32.2683 30.3138 32.3413 28.5445C32.4983 24.7384 33.175 19.7558 35.5598 14.9327C38.8238 8.33132 43.1156 4.29927 45.8603 1.89851C46.8039 6.25529 49.3763 14.5743 54.0929 21.5451C54.6553 22.3762 55.2169 23.1901 55.7708 23.9928C58.4278 27.8432 60.9085 31.438 62.4487 35.4573C64.2795 40.2349 64.7694 45.615 62.3743 52.6387C57.6386 66.5261 44.3746 69.4338 37.7 68.9517C35.3765 68.7839 30.4798 67.7049 25.8217 64.6682C21.1934 61.6509 16.8151 56.7149 15.3897 48.7874C14.2511 42.455 15.75 36.7252 18.0475 32.3001C19.9203 28.6929 22.2793 26.0345 24.0757 24.6421Z"
              stroke="#19468B"
              stroke-width="2"
            />
            <path
              d="M41.599 37.9752C41.9729 40.1635 42.8132 43.5928 45.1278 47.0557C45.4963 47.607 45.8963 48.1586 46.3029 48.7191C48.5997 51.8857 51.1032 55.3374 49.293 60.6447C47.1686 66.8734 41.2158 68.1966 38.1966 67.9786C37.1787 67.9051 34.9584 67.4203 32.8461 66.0436C30.7637 64.6863 28.8007 62.4761 28.1587 58.9062C27.6324 55.9796 28.0004 53.3496 28.7162 51.3464C29.0743 50.3441 29.5149 49.5119 29.9614 48.8851C30.0312 48.787 30.1003 48.6953 30.1682 48.6098C30.4302 49.3039 30.7804 50.0564 31.2217 50.755C32.0109 52.0044 33.2492 53.3225 35.0043 53.4838C35.3817 53.5185 35.6867 53.3461 35.8686 53.1425C36.0158 52.9776 36.0752 52.8057 36.0984 52.7306C36.1476 52.5707 36.1585 52.413 36.163 52.3301C36.1736 52.1386 36.1676 51.8831 36.1611 51.6286C36.1602 51.5914 36.1592 51.5534 36.1581 51.5144C36.1443 50.9863 36.1261 50.2941 36.1598 49.4785C36.2322 47.7233 36.5438 45.4562 37.6231 43.274C38.846 40.8014 40.3806 39.1184 41.599 37.9752ZM34.1648 52.2389C34.1648 52.2389 34.1647 52.2389 34.1647 52.2389C34.1642 52.2433 34.164 52.2457 34.164 52.2458C34.1641 52.2459 34.1644 52.2437 34.1648 52.2389Z"
              stroke="#A81D7B"
              stroke-width="2"
            />
            <path d="M24 15L24 9" stroke="#A81D7B" stroke-width="2" />
            <path d="M29 21L29 16" stroke="#A81D7B" stroke-width="2" />
            <path d="M31 11V3" stroke="#A81D7B" stroke-width="2" />
          </svg>
          Свербіж та печіння
        </li>
        <li class="symptoms__complain">
          <svg
            width="80"
            height="70"
            viewBox="0 0 80 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M57.5 31.466C57.5 45.4768 46.1912 56.8277 32.25 56.8277C18.3088 56.8277 7 45.4768 7 31.466C7 17.4551 18.3088 6.10425 32.25 6.10425C46.1912 6.10425 57.5 17.4551 57.5 31.466Z"
              fill="white"
              stroke="#19468B"
              stroke-width="2"
            />
            <path
              d="M51 49.0405L66 64.1044"
              stroke="#19468B"
              stroke-width="2"
            />
            <circle cx="25" cy="22.5" r="2" fill="#A81D7B" />
            <circle cx="25" cy="28.5" r="2" fill="#A81D7B" />
            <circle cx="31" cy="24.5" r="2" fill="#A81D7B" />
            <circle cx="36" cy="22" r="2" fill="#A81D7B" />
            <circle cx="36" cy="15" r="2" fill="#A81D7B" />
            <circle cx="31" cy="18.5" r="2" fill="#A81D7B" />
            <circle cx="31" cy="30.5" r="2" fill="#A81D7B" />
            <circle cx="29" cy="36.5" r="2" fill="#A81D7B" />
            <circle cx="37" cy="34.5" r="2" fill="#A81D7B" />
            <circle cx="37" cy="28.5" r="2" fill="#A81D7B" />
            <circle cx="42" cy="24" r="2" fill="#A81D7B" />
            <circle cx="22" cy="35" r="2" fill="#A81D7B" />
            <circle cx="20" cy="25" r="2" fill="#A81D7B" />
            <circle cx="18" cy="31" r="2" fill="#A81D7B" />
            <circle cx="34" cy="46" r="2" fill="#A81D7B" />
            <circle cx="40" cy="43" r="2" fill="#A81D7B" />
            <circle cx="39" cy="49" r="2" fill="#A81D7B" />
            <circle cx="25" cy="16" r="2" fill="#A81D7B" />
            <circle cx="45" cy="41" r="2" fill="#A81D7B" />
            <circle cx="20" cy="19" r="2" fill="#A81D7B" />
          </svg>
          Набряк та висипку
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getElementVisible } from "@/helper.js";

export default {
  data: () => ({
    isVisComplains: false,
  }),
  methods: {
    animate() {
      if (getElementVisible("complains")) {
        this.isVisComplains = true;
      }
    },
  },
  created() {
    window.addEventListener("scroll", this.animate);
  },
  destroyed() {
    window.removeEventListener("scroll", this.animate);
  },
};
</script>

<style lang="scss">
.symptoms {
  transform: translateY(-75px);
  padding-bottom: 25px;
  overflow: hidden;

  &__text-upper {
    width: 40%;
    margin-bottom: 48px;
    font-size: 18px;
    line-height: 150%;
    text-transform: uppercase;
    color: #19468b;
  }

  &__complains {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;

    &.active {
      .symptoms__complain {
        opacity: 1;
        transform: translateX(0);

        &:nth-of-type(2) {
          transition-delay: 0.2s;
        }

        &:nth-of-type(3) {
          transition-delay: 0.4s;
        }
      }
    }
  }

  &__complain {
    display: flex;
    flex-direction: column;
    max-width: 30%;
    font-size: 22px;
    line-height: 150%;
    color: #19468b;
    opacity: 0;
    transform: translateX(100%);
    transition: 0.8s;

    svg {
      margin-bottom: 15px;
    }
  }
}

@media (max-width: 1100px) {
  .symptoms {
    transform: translateY(-120px);
    padding-bottom: 0;

    &__text-upper {
      width: 60%;
      margin-bottom: 30px;
      font-size: 12px;
    }

    &__complains {
      margin-top: 30px;
    }

    &__complain {
      font-size: 18px;

      svg {
        margin-bottom: 15px;
      }
    }
  }
}

@media (max-width: 769px) {
  .symptoms {
    transform: translateY(-60px);
  }
}

@media (max-width: 675px) {
  .symptoms {
    &__text-upper {
      width: 90%;
      margin-bottom: 30px;
      font-size: 10px;
    }

    &__complains {
      margin-top: 25px;
      flex-direction: column;
    }

    &__complain {
      display: grid;
      grid-template-columns: 20% 80%;
      gap: 15px;
      align-items: center;
      font-size: 16px;
      max-width: 95%;
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 0;
      }

      svg {
        margin-bottom: 0;
        width: 100%;

        path {
          max-width: 100%;
        }
      }
    }
  }
}
</style>
