<template>
  <div class="popup">
    <div class="popup__bg" @click="closePopup"></div>

    <div class="popup__leave popup__container" v-if="isLeaveActive">
      <div class="popup__closer" @click="closePopup"></div>
      <div class="popup__title">
        Вас буде перенаправлено на<br/>
        зовнішний сайт
      </div>
      <div class="popup__buttons">
        <div>
          <a
              class="popup__button popup__button--liki"
              @click="openLink('https://liki24.com/uk/search/lomeksin/')"
              onClick="gtag('event', 'click', { 'event_category': 'кнока liki24.com', 'event_label': 'кнока liki24.com'})"
          >
            <p>liki24.com</p>
          </a>
          <a
              class="popup__button popup__button--tabletki"
              @click="openLink('https://tabletki.ua/uk/search/%D0%BB%D0%BE%D0%BC%D0%B5%D0%BA%D1%81%D0%B8%D0%BD/')"
              onClick="gtag('event', 'click', { 'event_category': 'кнока tabletki.ua', 'event_label': 'кнока tabletki.ua'})"
          >
            <p>tabletki.ua</p>
          </a>
        </div>
        <div>
          <a
              class="popup__button popup__button--helse"
              @click="openLink('https://helsi.me/liki/kyiv?page=1&per-page=6&s=%D0%9B%D0%BE%D0%BC%D0%B5%D0%BA%D1%81%D0%B8%D0%BD&settlementId=1')"
              onClick="gtag('event', 'click', { 'event_category': 'кнока helsi.me', 'event_label': 'кнока helsi.me'})"
          >
            <p>helsi.me</p>
          </a>
        </div>
      </div>
    </div>

    <div
        class="popup__instruction popup__container"
        v-if="isInstructionsActive"
    >
      <div class="popup__closer" @click="closePopup"></div>
      <div class="popup__title">Оберіть форму препарату</div>
      <ul class="popup__list">
        <li class="popup__item">
          <a href="/pdf/LOMEXIN_600mg.pdf" class="popup__item-link" download>
            Ломексин<sup>®</sup>&nbsp;600 мг, 1 капсула вагінальна м’яка
          </a>
        </li>
        <li class="popup__item">
          <a href="/pdf/LOMEXIN_200mg.pdf" class="popup__item-link" download>
            Ломексин<sup>®</sup>&nbsp;200 мг, 3 капсули вагінальні м’які
          </a>
        </li>
        <li class="popup__item">
          <a
              href="/pdf/LOMEXIN_cream_78mg.pdf"
              class="popup__item-link"
              download
          >
            Ломексин<sup>®</sup>&nbsp;крем вагінальний 2%, 78г
          </a>
        </li>
        <li class="popup__item">
          <a
              href="/pdf/LOMEXIN_cream_30mg.pdf"
              class="popup__item-link"
              download
          >

            Ломексин<sup>®</sup>&nbsp;крем вагінальний 2%, 30г
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isLeaveActive: Boolean,
    isInstructionsActive: Boolean,
  },
  methods: {
    closePopup() {
      setTimeout(() => {
        this.$emit("closePopup");
      }, 500);
    },
    openLink(link) {
      setTimeout(() => {
        window.open(link);
      }, 500);
    }
  },
};
</script>

<style lang="scss" scoped>
.popup {
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(12, 40, 83, 0.6);
  backdrop-filter: blur(120px);

  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    left: 50%;
    top: 50%;
    max-width: 843px;
    padding: 120px 0;
    transform: translate(-50%, -50%);
    background: #ffffff;
    box-shadow: 0px 15px 50px rgba(0, 17, 44, 0.25);
  }

  &__closer {
    position: absolute;
    top: 25px;
    right: 25px;
    width: 50px;
    height: 50px;
    background-image: url(../assets/img/icn_close.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
  }

  &__title {
    margin-bottom: 50px;
    font-weight: bold;
    font-size: 36px;
    line-height: 120%;
    text-align: center;
    color: #19468b;

    strong {
      color: #a81d7b;
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    flex-direction: column;

    > div {
      display: flex
    }
  }

  &__button {
    width: 180px;
    height: 95px;
    font-weight: bold;
    font-size: 20px;
    line-height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 50%;
    transition: 0.4s;

    p {
      transition: 0.4s;
      transform: rotate(0);
    }

    &--liki {
      color: #ffffff;
      margin-right: 20px;
      background: #a81d7b;
      border: 2px solid transparent;

      &:active,
      &:focus {
        background-color: #bb2e8d;
      }
    }

    &--helse {
      display: flex;
      order: 2;
      background: #feb7e7;
      margin: 10px auto 0 auto;
      color: white;
    }

    &--tabletki {
      color: #fff;
      background: #06A19B;
      border: 2px solid transparent;

      &:active,
      &:focus {
        background-color: #09BBB4;
      }
    }

    &:hover {
      transform: rotate(-18deg);

      p {
        transform: rotate(18deg);
      }

      @media (max-width: 769px) {
        transform: rotate(0);
        animation: rotate .4s alternate;

        p {
          transform: rotate(0);
          animation: rotateBack .4s alternate;
        }
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
  }

  &__item {
    font-weight: bold;
    font-size: 22px;
    line-height: 150%;

    &-link {
      display: inline-block;
      width: 100%;
      padding: 20px 25px;
      margin-bottom: 20px;
      border: 2px solid;
      border-radius: 40px;
      text-align: center;
      text-transform: uppercase;
      cursor: pointer;
      transition: 0.4s;

      sup {
        transform: translateY(25%);
      }
    }

    &:nth-of-type(1) {
      .popup__item-link {
        color: #a81d7b;

        &:hover {
          color: #fff;
          background-color: #a81d7b;
          border-color: #a81d7b;
        }
      }
    }

    &:nth-of-type(2) {
      .popup__item-link {
        color: #06a19b;

        &:hover {
          color: #fff;
          background-color: #06a19b;
          border-color: #06a19b;
        }
      }

    }

    &:nth-of-type(3) {
      .popup__item-link {
        color: #664689;

        &:hover {
          color: #fff;
          background-color: #664689;
          border-color: #664689;
        }
      }
    }

    &:nth-of-type(4) {
      .popup__item-link {
        color: #3db0de;

        &:hover {
          color: #fff;
          background-color: #3db0de;
          border-color: #3db0de;
        }
      }
    }
  }
}

@media (max-width: 769px) {
  .popup {
    &__container {
      max-width: 690px;
      padding: 65px 0;
    }

    &__title {
      margin-bottom: 22px;
      font-size: 24px;
    }

    &__button {
      width: 120px;
      height: 65px;
      font-size: 15px;
      line-height: 34px;
    }

    &__item {
      margin-bottom: 15px;
      font-size: 15px;
    }
  }
}

@media (max-width: 675px) {
  .popup {
    &__container {
      justify-content: center;
      max-width: 100%;
      height: 100%;
      padding: 43px 20px;
    }

    &__closer {
      width: 20px;
      height: 20px;
      top: 20px;
      right: 20px;
    }

    &__button {
      width: 100px;
      height: 55px;
      font-size: 12px;
      line-height: 22px;
    }

    &__title {
      margin-bottom: 15px;
      font-size: 18px;
    }

    &__item {
      margin-bottom: 10px;
      font-size: 10px;
      height: 70px;

      &-link {
        padding: 20px 40px;
      }
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-18deg);
  }
}

@keyframes rotateBack {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(18deg);
  }
}
</style>
