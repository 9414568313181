<template>
  <header
    class="header headroom"
    id="header"
    :class="{ 'headroom--unpinned': scrolled, active: menuOpened }"
  >
    <div class="container header__container">
      <div
        class="header__closer gtm__closer"
        :class="{ active: menuOpened }"
        @click.stop="openMenu()"
      >
        <div class="header__dash">
          <svg width="24" height="2" viewBox="0 0 24 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="2" fill="#19468B"/>
          </svg>
        </div>
        <div class="header__dash">
          <svg width="24" height="2" viewBox="0 0 24 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="2" fill="#19468B"/>
          </svg>
        </div>
      </div>
      <a class="header__logo logo gtm__home" @click.stop="scrollTo('slider')">
        <img src="../assets/img/logo_lomexin-header.svg" alt="" />
      </a>
      <nav class="header__nav nav" :class="{ active: menuOpened }">
        <ul class="nav__list">
          <li class="nav__item">
            <a class="nav__link gtm__about" @click.stop="scrollTo('about')"> Про молочницю </a>
          </li>
          <li class="nav__item">
            <a class="nav__link gtm__cure" @click.stop="scrollTo('cure')">
              Лікування
            </a>
          </li>
          <li class="nav__item">
            <a class="nav__link gtm__cure" @click.stop="scrollTo('usage')">
              Як застосовувати Ломексин<sup>®</sup>
            </a>
          </li>
          <li class="nav__item">
            <a class="nav__link gtm__prevent" @click.stop="scrollTo('prevent')">
              Профілактика
            </a>
          </li>
          <li class="nav__item">
            <button
              class="nav__btn btn gtm__find-in-drugstore"
              @click.stop="openPopup()"
              onClick="gtag('event', 'click', { 'event_category': 'кнока `Знайти в аптеці` меню', 'event_label': 'кнока `Знайти в аптеці` меню'})"
            >
              Знайти в аптеці
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
  data: () => ({
    limitPosition: 100,
    scrolled: false,
    lastPosition: 0,
    menuOpened: false,
  }),
  methods: {
    handleScroll() {
      if (
        this.lastPosition < window.scrollY &&
        this.limitPosition < window.scrollY
      ) {
        this.scrolled = true;
      }

      if (this.lastPosition > window.scrollY) {
        this.scrolled = false;
      }

      this.lastPosition = window.scrollY;
    },
    scrollTo(href) {
      this.$emit("scrollTo", href);
      this.openMenu();
    },
    openMenu() {
      const content = document.querySelector('.content');

      this.menuOpened = !this.menuOpened;

      if (this.menuOpened) {
        content.style.filter = 'blur(15px)';
      } else {
        content.style.filter = 'blur(0)';
      }
    },
    openPopup() {
      this.$emit("openPopup", "LEAVE");
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    if (this.menuOpened) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
.headroom {
  will-change: transform;
  transition: transform 200ms linear;

  &--pinned {
    transform: translateY(0%);
  }

  &--unpinned {
    transform: translateY(-100%);
  }
}

.header {
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;
  padding: 30px 0;
  background-color: #fff;

  &__closer {
    display: none;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    height: 30px;
    cursor: pointer;

    img {
      max-height: 100%;
    }
  }
}

.nav {
  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__item {
    margin-left: 45px;
    flex-shrink: 0;
  }

  &__link {
    font-weight: bold;
    font-size: 16px;
    line-height: 15px;
    color: #19468b;
    padding: 5px 0;
    transition: 0.4s;

    &:hover {
      color: #a81d7b;
    }
  }

  &__btn {
    position: relative;
    font-weight: bold;
    font-size: 16px;
    line-height: 15px;
    color: #a81d7b;

    &::before {
      content: "";
      position: absolute;
      left: 9px;
      right: 9px;
      top: -20px;
      bottom: -20px;
      background-color: #feb7e7;
      border-radius: 50%;
      transform: rotate(-18deg);
      transition: all 0.4s;
      z-index: -1;
    }

    &:hover::before {
      transform: rotate(0deg);
    }

    &:active::before {
      background-color: #ffceef;
    }
  }
}

@media (max-width: 1235px) {
  .header {
    padding: 25px 0;

    &__logo {
      max-height: 18px;
    }
  }

  .nav {
    &__item {
      margin-left: 30px;
    }

    &__link {
      font-size: 12px;
      line-height: 11px;
    }

    &__btn {
      font-size: 12px;
      line-height: 11px;

      &::before {
        content: "";
        top: -15px;
        bottom: -15px;
      }
    }
  }
}

//@media (max-width: 769px) {
//  .nav {
//    &__item {
//      margin-left: 10px;
//    }
//  }
//}

@media (max-width: 870px) {
  .headroom--unpinned.active {
    transform: translateY(0);
  }

  .header {
    padding: 18px 0;

    &.active {
      background-color: transparent;
    }

    &__logo {
      position: absolute;
      z-index: 100;
      left: 50%;
      transform: translateX(-50%);
    }

    &__dash {
      display: flex;
      width: 100%;
      height: 2px;
      transition: all 0.4s;

      &:first-of-type {
        margin-bottom: 4px;
      }
    }

    &__closer {
      position: relative;
      z-index: 100;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 24px;
      height: 24px;

      &.active {
        .header__dash {
          &:nth-of-type(1) {
            transform: translateY(2px) rotate(45deg);
          }
          &:nth-of-type(2) {
            transform: translateY(-4px) rotate(-45deg);
          }
        }
      }
    }
  }

  .nav {
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    transform: translateY(-100%);
    transition: all 0.4s;

    &.active {
      transform: translateY(0);

      .nav__list {
        flex-direction: column;
        justify-content: center;
        height: 100%;
      }

      .nav__item {
        margin: 0 0 60px;
      }

      .nav__link {
        font-size: 16px;
        line-height: 15px;
      }

      .nav__btn {
        font-size: 16px;
        line-height: 15px;

        &::before {
          top: -20px;
          bottom: -20px;
        }
      }
    }
  }
}
</style>
