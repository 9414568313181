<template>
  <section class="message" :class="{ active: isVisMessage }" id="message">
    <div class="container message__container">
      <div class="message__text">
        <div class="message__row desc">
          <h1 class="content__primary-title message__title">
            А при повторному
          </h1>
        </div>
        <div class="message__row mob">
          <h1 class="content__primary-title message__title">
            А при
          </h1>
        </div>
        <div class="message__row mob">
          <h1 class="content__primary-title message__title">
            повторному
          </h1>
        </div>
        <div class="message__row desc">
          <h1 class="content__primary-title message__title">
            виникненні молочниці,
          </h1>
        </div>
         <div class="message__row mob">
          <h1 class="content__primary-title message__title">
            виникненні
          </h1>
        </div>
         <div class="message__row mob">
          <h1 class="content__primary-title message__title">
            молочниці, ви
          </h1>
        </div>
        <div class="message__row desc">
          <h1 class="content__primary-title message__title">
            ви завжди можете
          </h1>
        </div>
        <div class="message__row mob">
          <h1 class="content__primary-title message__title">
            завжди можете
          </h1>
        </div>
        <div class="message__row">
          <h1 class="content__primary-title message__title">покластися на</h1>
        </div>
        <div class="message__row">
          <h1 class="content__primary-title message__title">
            <strong>Ломексин<sup>®</sup>!</strong>
          </h1>
        </div>
        <div class="parallax__layer message__elips" data-speed="10"></div>
        <svg
          class="parallax__layer"
          data-speed="8"
          width="185"
          height="185"
          viewBox="0 0 185 185"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M92.5 0L92.5179 92.409L127.898 7.04111L92.5515 92.4232L157.907 27.0927L92.5768 92.4485L177.959 57.1016L92.591 92.4821L185 92.5L92.591 92.5179L177.959 127.898L92.5768 92.5515L157.907 157.907L92.5515 92.5768L127.898 177.959L92.5179 92.591L92.5 185L92.4821 92.591L57.1016 177.959L92.4485 92.5768L27.0927 157.907L92.4232 92.5515L7.04111 127.898L92.409 92.5179L0 92.5L92.409 92.4821L7.04111 57.1016L92.4232 92.4485L27.0927 27.0927L92.4485 92.4232L57.1016 7.04111L92.4821 92.409L92.5 0Z"
            stroke="#19468B"
            stroke-width="2"
          />
        </svg>
      </div>
    </div>
  </section>
</template>

<script>
import { getElementVisible } from "@/helper.js";

export default {
  data: () => ({
    speed: null,
    yPos: null,

    isVisMessage: false,
  }),
  methods: {
    parallax() {
      const layers = document.querySelectorAll(".message .parallax__layer");
      const target = document.getElementById("message");
      const top = target.getBoundingClientRect().top;

      [...layers].forEach((item) => {
        this.speed = item.dataset.speed;
        this.yPos = (window.innerWidth - top * this.speed) / 100;
        item.style.transform = `translate(0px, ${this.yPos}px) rotate(-10deg)`;
      });
    },
    animate() {
      if (getElementVisible("message")) {
        this.isVisMessage = true;
      }
    },
  },
  created() {
    window.addEventListener("scroll", this.parallax);
    window.addEventListener("scroll", this.animate);
  },
  destroyed() {
    window.removeEventListener("scroll", this.parallax);
    window.removeEventListener("scroll", this.animate);
  },
};
</script>

<style lang="scss" scoped>
.message {
  padding-bottom: 200px;

  &__row {
    margin-bottom: 10px;

    &:last-of-type {
      margin: 0;
    }

    &.mob {
      display: none;
    }
  }

  &__text {
    position: relative;
  }

  &__elips {
    position: absolute;
    top: 15%;
    left: -5%;
    width: 714px;
    height: 396px;
    border-radius: 50%;
    border: 2px solid #a81d7b;
    transform: rotate(-10deg);
    opacity: 0;
    transition-delay: 1s;
    transition-property: opacity;
    transition-duration: 1.4s;
  }

  svg {
    position: absolute;
    bottom: -30%;
    right: 0;
    opacity: 0;
    transition-delay: 1s;
    transition-property: opacity;
    transition-duration: 1.4s;
  }

  &__title {
    color: #19468b;
    transform: translateY(120%);
    transition: 0.8s;
    transition-delay: 0.4s;

    strong {
      color: #a81d7b;
    }
  }

  &__row {
    overflow: hidden;
  }

  &.active {
    .message__title {
      transform: translateY(0);
    }

    .message__elips,
    svg {
      opacity: 1;
    }
  }
}

@media (max-width: 1100px) {
  .message {
    padding-bottom: 170px;

    &__elips {
      width: 60%;
      height: 100%;
      left: -2%;
    }

    svg {
      bottom: -60%;
      right: 10px;
      width: 140px;
      height: 140px;
    }
  }
}

@media (max-width: 675px) {
  .message {
    padding-bottom: 170px;

    &__row {
      &.desc {
        display: none;
      }

      &.mob {
        display: block;
      }
    }

    &__elips {
      width: 90%;
      height: 160px;
      left: 0;
      top: 55%;
    }

    svg {
      bottom: -50%;
      right: 10px;
      width: 80px;
      height: 80px;
    }
  }
}
</style>
