<template>
  <section class="links">
    <div class="container links__container">
      <h3 class="links__title">Посилання</h3>

      <ul class="links__list">
        <li class="links__item">
          <strong
            >* Блокує синтез кислих аспарагінових протеаз грибів роду Candida –
            унікальний механізм, властивий тільки фентиконазолу, на відміну від
            інших азолів (клотримазолу, міконазолу, кетоконазолу).</strong
          >
        </li>
        <li class="links__item">
          <strong
            >** Під час лікування слід дотримуватися рекомендацій Інструкції для
            медичного застосування лікарського засобу.</strong
          >
        </li>
      </ul>

      <ol class="links__list">
        <li class="links__item">
          Tumietto F, Giacomelli L. Fenticonazole: an effective topical
          treatment for superficial mycoses as the first-step of antifungal
          stewardship program. Eur Rev Med Pharmacol Sci. 2017
          Jun;21(11):2749-2756. PMID: 28678309.
        </li>
        <li class="links__item">
          Інструкція для медичного застосування лікарського засобу <nobr>Ломексин<sup>®</sup></nobr>, капсули вагінальні м’які по 600 мг. <nobr>РП UA/6094/01/01.</nobr>
        </li>
        <li class="links__item">
          Jeanmonod R, Jeanmonod D. Vaginal Candidiasis. [Updated 2020 Nov 21].
          In: StatPearls [Internet]. Treasure Island (FL): StatPearls
          Publishing; 2021 Jan. Available from:
          <a href="https://www.ncbi.nlm.nih.gov/books/NBK459317/"
            >https://www.ncbi.nlm.nih.gov/books/NBK459317/</a
          >
        </li>
        <li class="links__item">
          Пестрикова Т.Ю., Юрасова Е.А., Котельникова А.В. Вульвовагинальный
          кандидоз: современный взгляд на проблему. РМЖ. Мать и дитя.
          2017;25(26):1965-1970.
        </li>
        <li class="links__item">
          Antonopoulou, S. et al. (2009). Fenticonazole Activity Measured by the
          Methods of the European Committee on Antimicrobial Susceptibility
          Testing and CLSI against 260 Candida Vulvovaginitis Isolates from Two
          European Regions and Annotations on the Prevalent Genotypes.
          Antimicrobial Agents and Chemotherapy, 53(5), 2181–2184.
        </li>
        <li class="links__item">
          Інструкція для медичного застосування лікарського засобу <nobr>Ломексин<sup>®</sup></nobr>, капсули вагінальні м’які по 200 мг. <nobr>РП UA/6094/01/02.</nobr>
        </li>
        <li class="links__item">
          Інструкція для медичного застосування лікарського засобу <nobr>Ломексин<sup>®</sup></nobr>, крем вагінальний 2%. <nobr>РП UA/6094/03/01.</nobr>
        </li>
      </ol>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.links {
  padding: 110px 0 170px;
  background: #e2cef7;
  border-radius: 60px 60px 0 0;

  &__title {
    font-size: 18px;
    line-height: 54px;
    text-transform: uppercase;
    color: #19468b;
  }

  &__list {
    margin-top: 50px;
    font-size: 18px;
    line-height: 160%;
    color: #19468b;
  }

  &__item {
    margin-bottom: 10px;

    a {
      text-decoration: underline;
      word-break: break-all;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 1100px) {
  .links {
    padding: 78px 0 160px;

    &__title {
      font-size: 15px;
      line-height: 15px;
    }

    &__list {
      margin-top: 40px;
      font-size: 14px;
    }

    ol {
      padding-left: 20px;
    }

    &__item {
      margin-bottom: 8px;
    }
  }
}

@media (max-width: 675px) {
  .links {
    padding: 55px 0 100px;
    border-radius: 40px 40px 0 0;

    &__title {
      font-size: 10px;
      line-height: 10px;
    }

    &__list {
      margin-top: 20px;
      font-size: 12px;
    }

    ol {
      padding-left: 20px;
    }

    &__item {
      margin-bottom: 5px;
    }
  }
}
</style>
