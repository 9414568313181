<template>
  <div class="app">
    <Header @scrollTo="scrollTo" @openPopup="openPopup" />
    <main class="content">
      <Slider />
      <About />
      <div
        class="up"
        @click="scrollTo('slider')"
        tabindex="0"

        ref="upBtn"
      >
        <svg
          class="desc"
          width="16"
          height="42"
          viewBox="0 0 16 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.53035 1.18352C8.13982 0.792995 7.50666 0.792995 7.11614 1.18352L0.752174 7.54748C0.36165 7.938 0.36165 8.57117 0.752174 8.96169C1.1427 9.35222 1.77586 9.35222 2.16639 8.96169L7.82324 3.30484L13.4801 8.96169C13.8706 9.35222 14.5038 9.35222 14.8943 8.96169C15.2848 8.57117 15.2848 7.938 14.8943 7.54748L8.53035 1.18352ZM8.82324 41.8906L8.82324 1.89062H6.82324L6.82324 41.8906H8.82324Z"
            fill="white"
          />
        </svg>
        <svg
          class="mob"
          width="16"
          height="27"
          viewBox="0 0 16 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.65657 0.690197C8.26605 0.299673 7.63288 0.299673 7.24236 0.690197L0.878395 7.05416C0.487871 7.44468 0.487871 8.07785 0.878395 8.46837C1.26892 8.8589 1.90208 8.8589 2.29261 8.46837L7.94946 2.81152L13.6063 8.46837C13.9968 8.8589 14.63 8.8589 15.0205 8.46837C15.4111 8.07785 15.4111 7.44468 15.0205 7.05416L8.65657 0.690197ZM8.94946 26.6031L8.94946 1.3973H6.94946L6.94946 26.6031H8.94946Z"
            fill="white"
          />
        </svg>
      </div>
      <Complains />
      <Cure @openPopup="openPopup" />
      <Usage />
      <Prevent />
      <Message />
      <Links />
      <Footer @openPopup="openPopup" class="desc" />
    </main>
    <Footer @openPopup="openPopup" class="mob" />
    <div class="warning">
      <img src="./assets/img/samolikuvannya.png" alt="" />
      <img src="./assets/img/skidlyvym.png" alt="" />
    </div>
    <Popup
      v-if="isPopupActive"
      :isInstructionsActive="isInstructionsActive"
      :isLeaveActive="isLeaveActive"
      @closePopup="closePopup"
    />
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import Slider from "./components/Slider.vue";
import About from "./components/About.vue";
import Complains from "./components/Complains.vue";
import Usage from "./components/Usage.vue";
import Prevent from "./components/Prevent.vue";
import Cure from "./components/Cure.vue";
import Message from "./components/Message.vue";
import Links from "./components/Links.vue";
import Popup from "./components/Popup.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
    Slider,
    About,
    Complains,
    Usage,
    Prevent,
    Cure,
    Message,
    Links,
    Popup,
  },
  data: () => ({
    isPopupActive: false,
    isLeaveActive: false,
    isInstructionsActive: false,
  }),
  methods: {
    scrollTo(href) {
      const duration = 500;
      const target = document.getElementById(href);

      const top = target.getBoundingClientRect().top;
      const startPos = window.pageYOffset;
      const diff = top;

      let startTime = null;
      let requestId;

      const loop = function (currentTime) {
        if (!startTime) {
          startTime = currentTime;
        }
        const time = currentTime - startTime;

        const percent = Math.min(time / duration, 1);
        setTimeout(() => {
          window.scrollTo(0, startPos + diff * percent);
        }, 200);

        if (time < duration) {
          requestId = window.requestAnimationFrame(loop);
        } else {
          window.cancelAnimationFrame(requestId);
        }
      };
      requestId = window.requestAnimationFrame(loop);
      setTimeout(() => {
        this.$refs.upBtn.blur();
      }, 300);
    },
    closePopup() {
      this.isPopupActive = false;
      this.isInstructionsActive = false;
      this.isLeaveActive = false;
    },
    openPopup(popup) {
      this.isPopupActive = true;

      if (popup === "LEAVE") {
        this.isLeaveActive = true;
      } else if (popup === "INSTRUCTIONS") {
        this.isInstructionsActive = true;
      }
    },
  },
  mounted() {
    this.scrollTo("slider");
  },
};
</script>

<style lang="scss">
@import "@/assets/style/_fonts.scss";
@import "@/assets/style/_reset.scss";

.app {
  height: 100vh;

  .header {
    animation: section 0.5s alternate;
  }

  .about__description {
    animation: section 2.5s alternate;
  }

  .mob {
    display: none;
  }

  @media (max-width: 675px) {
    .desc {
      display: none;
    }

    .mob {
      display: block;
    }
  }
}

.container {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;

  @media (max-width: 769px) {
    padding: 0 14px;
  }
}

.content {
  filter: blur(0);
  transition: .4s;

  @media (min-width: 870px) {
    filter: blur(0) !important;
  }

  &__primary-title {
    font-size: 72px;
    line-height: 120%;
    font-weight: normal;
    text-transform: uppercase;

    @media (max-width: 1441px) {
      font-size: 60px;
    }

    @media (max-width: 1100px) {
      font-size: 40px;
    }

    @media (max-width: 675px) {
      font-size: 26px;
    }
  }

  &__secondary-title {
    margin-bottom: 60px;
    font-weight: bold;
    font-size: 56px;
    line-height: 54px;
    color: #19468b;

    @media (max-width: 1100px) {
      margin-bottom: 50px;
      font-size: 40px;
      line-height: 44px;
    }

    @media (max-width: 675px) {
      font-size: 26px;
      line-height: 30px;
      margin-bottom: 30px;
    }
  }

  &__third-title {
    font-weight: bold;
    font-size: 36px;
    line-height: 120%;
    color: #19468b;

    @media (max-width: 1100px) {
      font-size: 24px;
    }

    @media (max-width: 675px) {
      font-size: 18px;
    }
  }

  .marked {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: -8px;
      right: -8px;
      top: -6px;
      bottom: -5px;
      border-radius: 40px;
      border: 2px solid;

      @media (max-width: 769px) {
        top: -4px;
        bottom: -3px;
      }
    }
  }
}

.up {
  position: sticky;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -o-sticky;
  position: -ms-sticky;
  top: 80vh;
  z-index: 5;
  transform: translateY(-100%);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 112px;
  width: 61px;
  margin-right: 40px;
  margin-left: auto;
  border: 2px solid #a81d7b;
  border-radius: 50%;
  cursor: pointer;
  outline: none;

  svg {
    z-index: 6;
  }

  .mob {
    display: none;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #a81d7b;
    border-radius: 50%;
    transform: rotate(-47deg);
    transition: 0.4s;
  }

  &:hover {
    &::after {
      @media (min-width: 770px) {
        transform: rotate(0deg);
      }
    }
  }

  &:active,
  &:focus {
    &::after {
      background: #bb2e8d;

      @media (max-width: 769px) {
        transform: rotate(0deg);
        // animation: rotate .4s alternate;
      }
    }
  }

  @media (max-width: 1100px) {
    top: 90vh;
  }

  @media (max-width: 769px) {
    height: 70px;
    width: 35px;
    margin-right: 14px;

    .desc {
      display: none;
    }

    .mob {
      display: block;
      max-width: 30%;
    }
  }

  // @media (max-width: 650px) {
  //   // display: none;
  // }
}

.warning {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  height: 140px;
  display: flex;
  justify-content: space-between;
  padding: 22px 18px;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(50px);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    filter: blur(5px)
  }

  @media (max-width: 1100px) {
    height: 56px;
    padding: 10px;
  }

  img {
    &:first-of-type {
      width: 45%;
      height: 100px;
      flex-shrink: 1;

      @media (max-width: 1100px) {
        height: 40px;
      }
    }

    &:last-of-type {
      width: 54%;
      height: 105px;
      flex-shrink: 1;

      @media (max-width: 1100px) {
        height: 42px;
      }
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(-47deg);
  }
  100% {
    transform: rotate(0);
  }
}

@keyframes section {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  60% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
